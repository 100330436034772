import { configurePendo } from '@ucc/cc-config'
import jrnParser from 'classes/jrnParser'
import is from 'is'
import _module from 'module.js'
import moment from 'moment'

let _rootScope,
  _userService,
  _jrn,
  _partnerInfo,
  _portalApi,
  _pbxUtil,
  _q,
  _location,
  _featuresService,
  _FEATURE_FLAGS,
  _document,
  _globalState,
  _contactCenterConfigurationService,
  _licensesService

export default class LeftPanelController {
  constructor (
    $rootScope,
    $q,
    $state,
    portalApi,
    userService,
    pbxUtil,
    gettextCatalog,
    partnerInfo,
    $location,
    $document,
    featuresService,
    FEATURE_FLAGS,
    globalState,
    $log,
    portalConfig,
    contactCenterConfigurationService,
    licensesService,
    unifiedAdminDashboardAccessService,
    unifiedAdminLocationsService,
    unleashService,
    tryNewGoToAdminForm
  ) {
    'ngInject'
    _rootScope = $rootScope
    _userService = userService
    _jrn = jrnParser
    _partnerInfo = partnerInfo
    _portalApi = portalApi
    _pbxUtil = pbxUtil
    this.gettextCatalog = gettextCatalog
    _q = $q
    _location = $location
    _featuresService = featuresService
    _FEATURE_FLAGS = FEATURE_FLAGS
    _document = $document
    _globalState = globalState
    _contactCenterConfigurationService = contactCenterConfigurationService
    _licensesService = licensesService

    this.showMultiSelect = false
    this.isPlatformNumbersOrHigher = _globalState.isPlatformNumbersOrHigher
    this.isPlatformAdmin = _globalState.isPlatformAdmin
    this.pendingPbx = null
    this.isContactCenterConfigurationTabAllowed = false
    this.isStaging = false
    this.isWhiteLabel = _globalState.isWhiteLabel
    this.selectedJrn = _globalState.selectedPbx.jrn
    this.isNA = _globalState.isNA
    this.region = globalState.supportedRegions.find(
      ({ code }) => code === globalState.selectedPbx.region
    )
    this.state = $state
    this.globalState = globalState
    this.title = this.globalState.selectedPbx.name
    this.securityIcon = portalConfig.securityIcon
    this.zohoIcon = portalConfig.zohoIcon
    this.roomIcon = portalConfig.roomIcon
    this.dialInIcon = portalConfig.dialInIcon
    this.dialOutIcon = portalConfig.dialOutIcon
    this.cloudIcon = portalConfig.cloudIcon

    this.groupsIcon = portalConfig.groupsIcon
    this.globeIcon = portalConfig.globeIcon
    this.alertIcon = portalConfig.alertIcon
    this.faxIcon = portalConfig.faxIcon
    this.calendarIcon = portalConfig.calendarIcon
    this.cartIcon = portalConfig.cartIcon
    this.contactsIcon = portalConfig.contactsIcon
    this.hardphoneIcon = portalConfig.hardphoneIcon
    this.phoneIcon = portalConfig.phoneIcon
    this.voipIcon = portalConfig.voipIcon
    this.speakerOnIcon = portalConfig.speakerOnIcon
    this.lockIcon = portalConfig.lockIcon
    this.infoIcon = portalConfig.infoIcon
    this.launchIcon = portalConfig.launchIcon
    this.usersIcon = portalConfig.usersIcon
    this.filterIcon = portalConfig.filterIcon
    this.speakerIcon = portalConfig.speakerIcon
    this.flowchartIcon = portalConfig.flowchartIcon
    this.voicemailIcon = portalConfig.voicemailIcon
    this.wrenchIcon = portalConfig.wrenchIcon
    this.arrowIcon = portalConfig.arrowIcon
    this.settingsIcon = portalConfig.settingsIcon
    this.dashboardIcon = portalConfig.dashboardIcon
    this.reportIcon = portalConfig.reportIcon
    this.roleIcon = portalConfig.roleIcon
    this.siteIcon = portalConfig.siteIcon
    this.addressBook = portalConfig.addressBook
    this.showPhoenix = localStorage.getItem('admin-portal-phoenix') === 'true'
    this.unifiedAdminDashboardAccessService = unifiedAdminDashboardAccessService
    this.unifiedAdminLocationsService = unifiedAdminLocationsService
    this.unleashService = unleashService
    this.userContext = null
    this.tryNewGoToAdminForm = tryNewGoToAdminForm

    this.tabsAllowed = {
      setup: false,
      dashboard: false,
      systemHealth: false,
      orders: false,
      users: false,
      userGroups: false,
      phoneNumbers: false,
      callFilters: false,
      devices: false,
      hardware: false,
      corporateDirectories: false,
      pagingProfiles: false,
      pagingRealms: false,
      extensionsOverview: false,
      lines: false,
      ringGroups: false,
      callQueues: false,
      conferenceBridges: false,
      dialPlans: false,
      sharedVoicemailBoxes: false,
      virtualFaxMachines: false,
      dialByNameDirectories: false,
      schedules: false,
      crmMapping: false,
      holdMusic: false,
      soundClips: false,
      permissions: false,
      roles: false,
      networkAccess: false,
      systemSettings: false,
      contacts: false,
      userSynchronizations: false,
      security: false,
      locations: false,
      emergencyLocations: false,
      deviceStatus: false,
      unavailableLines: false,
      importExport: false,
      e911UnifiedAdmin: false
    }

    this.getGuidedSetupConfig()
    this.setPBXOnUnleashContext(_globalState.selectedPbxUUID, _globalState.selectedPbxUnifiedAdmin)

    if (_globalState.selectedPbx.featureFlags[_FEATURE_FLAGS.tryNewGoToAdmin]) {
      this.showMigrationDialog()
    }

    _userService
      .hasPlatformPermissions()
      .then(hasPermissions => {
        this.tabsAllowed.deviceStatus = hasPermissions
        this.tabsAllowed.importExport = hasPermissions
        this.isStaging = is.include($location.host(), 'staging')
        if (hasPermissions) {
          this.showMultiSelect = true
        } else {
          _userService.getAdministeredPbxs().then(pbxs => {
            if (pbxs.length > 1) {
              this.showMultiSelect = true
            }
          })
        }
        this.updateTabsAllowed()
      })
    _rootScope.$on('pbxUpdated', (evnt, pbx) => {
      // use exisiting feature flags, no need to retrieve it again.
      pbx.featureFlags = _globalState.selectedPbx.featureFlags
      pbx.licenses = _globalState.selectedPbx.licenses || []
      _globalState.selectedPbx = pbx
      this.isNA = _globalState.isNA
      this.region = globalState.supportedRegions.find(
        ({ code }) => code === globalState.selectedPbx.region
      )
      this.tabsAllowed.virtualFaxMachines = this.region.faxSupported
      _globalState.selectedPbx.isActivated =
        _globalState.selectedPbx.state === 'ACTIVE' &&
        (_globalState.selectedPbx.activationDate
          ? !moment(_globalState.selectedPbx.activationDate).isAfter(
            moment.utc()
          )
          : true)
      this.updateTabsAllowed()
    })

    _rootScope.$on('$stateChangeSuccess', async () => {
      if (this.pendingPbx) {
        let pbxAdminTitle = this.gettextCatalog.getString('PBX Administration')

        _globalState.selectedPbx = this.pendingPbx
        _globalState.selectedPbx.analyticsEnabled = false
        _globalState.selectedPbx.featureFlags = {}
        _globalState.selectedPbx.isActivated =
          _globalState.selectedPbx.state === 'ACTIVE' &&
          (_globalState.selectedPbx.activationDate
            ? !moment(_globalState.selectedPbx.activationDate).isAfter(
              moment.utc()
            )
            : true)

        // Store all feature flags on the PBX with their state as a boolean
        _globalState.selectedPbx.featureFlags = {}
        _globalState.selectedPbx.tempFeatureFlags.forEach((feature) => {
            if(feature.feature === _FEATURE_FLAGS.contactCenter) {
              _globalState.selectedPbx.analyticsEnabled = feature.setting === 'ENABLED'
            }
          _globalState.selectedPbx.featureFlags[feature.feature] = feature.setting === 'ENABLED'
        })

        this.updateTabsAllowed()

        _rootScope.$broadcast('pbxChanged', globalState.selectedPbx)

        this.setPBXOnUnleashContext(globalState.selectedPbxUUID)

        this.pendingPbx = null
      }
    })

    _rootScope.$on('cancelNavigation', () => {
      if (this.selectedJrn !== _globalState.selectedPbx.jrn) {
        this.selectedJrn = _globalState.selectedPbx.jrn
        this.pendingPbx = null
      }
    })

    _rootScope.$on('userContextLoaded', (_, userContext) => {
      this.loadPendo(userContext)
      this.userContext = userContext
    })
  }

  pbxChanged (newJrn) {
    let pbxId = _jrn.parse(newJrn).getAccount()
    let entitlements = null

    _partnerInfo.partnerApi = _portalApi.partnerBff.partners
    _partnerInfo.pbxApi = _portalApi.pbx.pbxs(pbxId)
    _q.all([
      _portalApi.pbx.pbxs(pbxId).get(),
      _partnerInfo.get(pbxId),
      _featuresService.getAllContextFeatures(pbxId),
      _licensesService.getLicenses(pbxId),
      _portalApi.numbers.pbxs(pbxId).pbxSmsCampaignDetails().get()
        .then(response => response.plain())
        .catch(() => {
          return {
            pbxHasACampaign: false,
            pbxIsUsingSms: false,
            pbxMayNeedACampaignToUseSms: false,
            pbxNeedsACampaignToUseSms: false,
            userIsAddOnAdmin: false
          }
        }),
    ]).then(data => {
      const pbx = data[0]
      return _userService.canManagePbxThroughPartner(pbx).then(res => {
        _globalState.canManagePbxThroughPartner = res
        return data
      })
    }).then(data=>{
      const pbx = data[0]
      return _licensesService.getEntitlements(pbx.lmiAccountKey).then(res => {
        entitlements = res
        return data
      })
    }).then(data => {
      const pbx = data[0]
      pbx.partnerInfo = data[1]
      pbx.tempFeatureFlags = data[2]
      pbx.licenses = data[3]
      pbx.pbxSmsCampaignDetails = data[4]
      pbx.entitlements = entitlements
      this.pendingPbx = pbx
      if (!_pbxUtil.redirectForWeightedLicensePbx(pbx) &&  !_pbxUtil.redirectForUnifiedAdminPbx(pbx) && !_pbxUtil.redirectForBossPartnerPbx(pbx)) {
        _pbxUtil.goToPbx(pbx.domain)
      }
    })
  }

  loadPendo(userContext) {

    const pendoParams = {
      accountKey: _globalState.selectedPbxLmiAccountKey,
      license: userContext.license,
      locale: _globalState.currentLanguage.langCode.replace('-', '_'),
      pbxId: _globalState.selectedPbxUUID,
      permissions: userContext.permissions,
      userKey: userContext.externalUserKey
    }

    configurePendo(pendoParams)

  }

  loadUserContext() {
    _contactCenterConfigurationService
      .getUserContext(_globalState.selectedPbxUUID)
      .then(userContext => {
        _rootScope.$broadcast('userContextLoaded', userContext);
      });
  }

  updateTabsAllowed () {
    this.loadUserContext()

    this.markAdminTabsAllowed()
    this.markManageSeatsTabAllowed()
    this.markManageUsersTabAllowed()
    this.markManageGroupsTabAllowed()
    this.markManageSettingsTabAllowed()
  }

  markAdminTabsAllowed() {
    Promise.all([
      _userService.isUserSuperAdmin(_globalState.selectedPbx),
      _userService.hasPbxAdminPermission(_globalState.selectedPbx)
    ]).then(data => {
      if (data[0] || data[1]) {
        this.showAllAdminTabs()
      }
    })
  }

  showAllAdminTabs() {
    this.tabsAllowed.dashboard = true
    this.tabsAllowed.systemHealth = this.unifiedAdminDashboardAccessService.isDashboardAvailable()
    this.tabsAllowed.phoneNumbers = true
    this.tabsAllowed.callFilters = true
    this.tabsAllowed.devices = true
    this.tabsAllowed.hardware = true
    this.tabsAllowed.users = true
    this.tabsAllowed.userGroups = true
    this.tabsAllowed.corporateDirectories = true
    this.tabsAllowed.pagingProfiles = true
    this.tabsAllowed.pagingRealms = true
    this.tabsAllowed.extensionsOverview = true
    this.tabsAllowed.lines = true
    this.tabsAllowed.ringGroups = true
    this.tabsAllowed.callQueues = true
    this.tabsAllowed.conferenceBridges = true
    this.tabsAllowed.dialPlans = true
    this.tabsAllowed.sharedVoicemailBoxes = true
    this.tabsAllowed.virtualFaxMachines = this.region.faxSupported
    this.tabsAllowed.dialByNameDirectories = true
    this.tabsAllowed.schedules = true
    this.tabsAllowed.holdMusic = true
    this.tabsAllowed.soundClips = true
    this.tabsAllowed.networkAccess = true
    this.tabsAllowed.systemSettings = true
    this.tabsAllowed.contacts = _globalState.selectedPbx.featureFlags[_FEATURE_FLAGS.contactsEnabled]
    this.tabsAllowed.userSynchronizations = _globalState.selectedPbx.featureFlags[_FEATURE_FLAGS.userSyncEnabled]
    this.tabsAllowed.security = true
    this.tabsAllowed.locations = _globalState.isLocationEnabled &&  !_globalState.isE911V3Enabled
    this.tabsAllowed.emergencyLocations = _globalState.isE911Enabled && !_globalState.isE911V3Enabled
    this.tabsAllowed.unavailableLines = true
    this.tabsAllowed.roles = true

    this.isE911Visible = _globalState.isE911Enabled &&  !_globalState.isE911V3Enabled
    this.isLocationVisible = _globalState.isLocationEnabled && !_globalState.isE911V3Enabled

    this.tabsAllowed.e911UnifiedAdmin = _globalState.isE911V3Enabled

    this.markZohoTabAllowed()
    this.markUnifiedAdminDashboardTabAllowed()
    this.markPermissionTabAllowed()
    this.markUserSyncTabAllowed()
    this.markContactCenterConfigurationTabAllowed()
    this.markOrdersTabAllowed()

    this.markUnifiedAdminLocationsTabAllowed()
  }

  markManageSeatsTabAllowed() {
    _userService.hasManageSeatsPermission(_globalState.selectedPbx).then(ret => {
      if (ret) {
        this.tabsAllowed.users = true
        this.tabsAllowed.userGroups = true
        this.tabsAllowed.devices = true
        this.tabsAllowed.hardware = true
      }
    })
  }

  markManageUsersTabAllowed() {
    _userService.hasManageUsersPermission(_globalState.selectedPbx).then(ret => {
      if (ret) {
        this.tabsAllowed.users = true
        this.tabsAllowed.userGroups = true
      }
    })
  }

  markManageSettingsTabAllowed() {
    Promise.all([
      _userService.hasManageSettingsPermission(_globalState.selectedPbx),
      _userService.hasManageSeatsPermission(_globalState.selectedPbx)
    ]).then(data => {
      if (data[0]) {
        this.tabsAllowed.users = true
        this.tabsAllowed.devices = true
        this.tabsAllowed.lines = true

        _globalState.selectedPbx.featureFlags[_FEATURE_FLAGS.e911Enabled] = _globalState.selectedPbx.featureFlags[_FEATURE_FLAGS.e911Enabled] && data[1]
      }
    })
  }

  markManageGroupsTabAllowed() {
    _userService.hasManageGroupsPermission(_globalState.selectedPbx).then(ret => {
      if (ret) {
        this.tabsAllowed.users = true
        this.tabsAllowed.userGroups = true
      }
    })
  }

  markContactCenterConfigurationTabAllowed() {
    _rootScope.$on('userContextLoaded', (_, userContext) => {
      this.isContactCenterConfigurationTabAllowed = _contactCenterConfigurationService.hasContactCenterConfigurationPermission(
        userContext
      ) && !this.isWhiteLabel;
    });
  }

  markUserSyncTabAllowed () {
    _featuresService
      .getContextFeature(
        _globalState._selectedPbxUUID,
        _FEATURE_FLAGS.userSyncEnabled
      )
      .then(featureFlag => {
        this.tabsAllowed.userSynchronizations = featureFlag.setting === 'ENABLED'
      })
  }

  markZohoTabAllowed () {
    _featuresService
      .getContextFeature(_globalState._selectedPbxUUID, _FEATURE_FLAGS.zohoZia)
      .then(featureFlag => {
        this.tabsAllowed.crmMapping = featureFlag.setting === 'ENABLED'
      })
  }

  markPermissionTabAllowed () {
    _userService.isPermissionTabAllowed(_globalState.selectedPbx).then(ret => {
      _globalState.writePermissions = ret
      this.tabsAllowed.permissions = ret
    })
  }

  markOrdersTabAllowed () {
    if (!this.isWhiteLabel) {
      _featuresService
        .getContextFeature(_globalState._selectedPbxUUID, _FEATURE_FLAGS.ordrPbxadminv5TabsOrders)
        .then(featureFlag => {
          this.tabsAllowed.orders = featureFlag.setting === 'ENABLED'
        })
    } else {
      this.tabsAllowed.orders = false
    }
  }

  markUnifiedAdminDashboardTabAllowed () {
    this.unifiedAdminDashboardAccessService.fetchIsDashboardAvailable().then((isAllowed) => {
      this.tabsAllowed.systemHealth = isAllowed
    });
  }

  markUnifiedAdminLocationsTabAllowed () {
    this.unifiedAdminLocationsService.fetchIsLocationsAvailable().then((isAllowed) => {
      this.tabsAllowed.e911UnifiedAdmin = isAllowed
    });
  }

  handleSelectPhoenixTab(path) {
    this.globalState.history.push(`/${_globalState.selectedPbx.domain}/${path}`)
  }

  async getGuidedSetupConfig() {
    if (!this.isWhiteLabel) {
      await _portalApi.ordering.pbxs(_globalState._selectedPbxUUID).guidedSetupConfig()
        .get()
        .then((data) => {
          _globalState.selectedPbx.setupEnabled = data.hasGuidedSetup
        })
        .catch(() => {
          _globalState.selectedPbx.setupEnabled = false
        })
        .finally(() => {
          this.tabsAllowed.setup = _globalState.selectedPbx.setupEnabled
        })
    } else {
      _globalState.selectedPbx.setupEnabled = false
      this.tabsAllowed.setup = _globalState.selectedPbx.setupEnabled
    }
  }

  setPBXOnUnleashContext(pbxId, unifiedAdmin){
    this.unleashService.updateContext({pbxId, unifiedAdmin})
  }

  showMigrationDialog() {
    const key = 'migration-dialog-last-viewed'
    const currentDate = new Date().toDateString()
    if (localStorage.getItem(key) === currentDate) {
      return
    }
    localStorage.setItem(key, currentDate)
    this.tryNewGoToAdminForm.open()
  }
}

_module.controller('LeftPanelCtrl', LeftPanelController)
